<template>
	<div
		v-html="label"
		:class="{
			'vc-button': true,
			'vc-button--selected': isSelected
		}"
		:style="{
			backgroundColor: isSelected ? activeColor : '#fff',
			borderColor: activeColor
		}"
		@click="actionHandler"
	/>
</template>

<script>
import "./Button.scss";

export default {
	name: "Button",
	props: {
		label: String,
		actionHandler: Function,
		isSelected: Boolean,
		activeColor: String
	}
};
</script>
