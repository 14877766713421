<template>
	<svg
		:width="width + 'px'"
		:height="height + 'px'"
		viewBox="0 0 23 19"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<!-- Generator: sketchtool 61.2 (101010) - https://sketch.com -->
		<title>Vocalls chatbot</title>
		<desc>Created with sketchtool.</desc>
		<g
			id="Design"
			stroke="none"
			stroke-width="1"
			fill="none"
			fill-rule="evenodd"
		>
			<g id="Chatbot_icons" transform="translate(-101.000000, -243.000000)">
				<g id="Group-chat-Copy-8" transform="translate(100.000000, 240.000000)">
					<rect id="bound" x="0" y="0" width="24" height="24"></rect>
					<path
						d="M20.4776218,3 C21.8728674,3 23.0039375,4.13107011 23.0039375,5.52631579 L23.0039375,13.1052632 L23.0205811,17.7770875 C23.0215728,18.0554372 22.7967296,18.2818881 22.5183799,18.2828798 C22.3840894,18.2833582 22.255161,18.2302231 22.1602026,18.1352648 L19.6565168,15.6315789 L15.9998323,15.631 L16,12.0239997 C16,10.4135375 14.7410888,9.09710982 13.1536833,9.00513343 L12.9760003,9 L6.16183229,9 L6.16183229,5.52631579 C6.16183229,4.13107011 7.29290239,3 8.68814808,3 L20.4776218,3 Z"
						id="Combined-Shape"
						:fill="color"
					></path>
					<path
						d="M11.9850559,11 C13.0896254,11 13.9850559,11.8954305 13.9850559,13 L13.9850559,18 C13.9850559,19.1045695 13.0896254,20 11.9850559,20 L4.10078614,20 L2.86369999,21.1840535 C2.66261277,21.3765203 2.34357418,21.3695321 2.15110736,21.1684449 C2.06132339,21.0746396 2.01120804,20.9498012 2.01120804,20.8199529 L2.01120804,18.32426 C1.99400175,18.2187196 1.98505595,18.1104045 1.98505595,18 L1.98505595,13 C1.98505595,11.8954305 2.88048645,11 3.98505595,11 L11.9850559,11 Z M11.5,16 L9.5,16 C9.22385763,16 9,16.2238576 9,16.5 C9,16.7761424 9.22385763,17 9.5,17 L9.5,17 L11.5,17 C11.7761424,17 12,16.7761424 12,16.5 C12,16.2238576 11.7761424,16 11.5,16 L11.5,16 Z M11.5,14 L6.5,14 C6.22385763,14 6,14.2238576 6,14.5 C6,14.7761424 6.22385763,15 6.5,15 L6.5,15 L11.5,15 C11.7761424,15 12,14.7761424 12,14.5 C12,14.2238576 11.7761424,14 11.5,14 L11.5,14 Z"
						id="Combined-Shape"
						:fill="color"
						opacity="0.5"
					></path>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	name: "MessageBubbleIcon",
	props: {
		color: {
			default: "#b526d8",
			type: String
		},
		width: {
			default: 32,
			type: Number
		},
		height: {
			default: 32,
			type: Number
		}
	}
};
</script>
