const languageParameters = {
	en: "en-GB",
	cs: "cs-CZ",
	sk: "sk-SK",
	pl: "pl-PL",
	it: "it-IT",
	al: "sq-AL",
	ro: "ro-RO",
	ne: "ne-NP",
	ms: "ms-MY",
	th: "th-TH"
};

export default languageParameters;
