import store from "../store";
import { MSG_TEXT_TYPE, MSG_RENDERED_STATE } from "../constants";
import { sendMessage as sendMessageToApi } from "../api";
import mapMessagesWithTyping from "./mapMessagesWithTyping";
import removeTagsFromString from "./removeTagsFromString";

const sendAndProcessMessage = (value, messageType) => {
	store.commit("deactivateOptions");
	store.commit("stopMessageGetterInterval");
	store.commit("setAllMessagesRenderedState");

	if (messageType === MSG_TEXT_TYPE) {
		if (value.trim() !== "") {
			store.commit("addMessage", {
				data: removeTagsFromString(value),
				type: MSG_TEXT_TYPE,
				state: MSG_RENDERED_STATE
			});
		} else {
			return null;
		}
	}

	if (store.getters.isConversationEnded) {
		return;
	}

	sendMessageToApi(value, messageType)
		.then(res => {
			mapMessagesWithTyping(res.data);
		})
		.catch(err => {
			console.error(err);
		});
};

export default sendAndProcessMessage;
