import { DEFAULT_THEME_COLOR } from "../constants";

const getThemeColor = () => {
	if (window.vocallsChatbot) {
		return window.vocallsChatbot.themeColor
			? window.vocallsChatbot.themeColor
			: DEFAULT_THEME_COLOR;
	}

	return DEFAULT_THEME_COLOR;
};

export default getThemeColor;
