import store from "../store";
import { MSG_OPTION_TYPE, MSG_GEOCODE_TYPE } from "../constants";

const commitMessageToStore = ({ value, type, content }, attributes) => {
	store.commit("addMessage", {
		data: value,
		type,
		isRobot: true,
		content,
		...(type === MSG_OPTION_TYPE && {
			isOptionsActive: true
		}),
		...(type === MSG_GEOCODE_TYPE && {
			isOptionsActive: true
		}),
		...attributes
	});
};

export default commitMessageToStore;
