import store from "../store";
import { MSG_TEXT_TYPE } from "../constants";

const isNewLastTextMessageInBlock = (newMessage, lastMessage) => {
	if (!newMessage.isTyping) {
		if (!lastMessage) {
			return true;
		} else {
			if (newMessage.type === MSG_TEXT_TYPE) {
				if (
					newMessage.type === MSG_TEXT_TYPE &&
					lastMessage.type === MSG_TEXT_TYPE &&
					newMessage.isRobot === lastMessage.isRobot
				) {
					store.commit("toggleLastInBlock");
				}
				return true;
			}
		}
	}

	return false;
};

export default isNewLastTextMessageInBlock;
