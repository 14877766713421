<template>
	<Icon
		v-if="actionHandler"
		:cssClass="{
			'vc-icon-button': true,
			[cssClass]: cssClass,
			'vc-icon-button--disabled': disabled
		}"
		:size="!size ? 'medium' : size"
		:color="color"
		:type="iconType"
		@click.native="actionHandler"
	/>
	<span v-else>
		<Icon
			:cssClass="{
				'vc-icon-button': true,
				'vc-icon-button--mouse-actions': true,
				'vc-icon-button--disabled': disabled,
				[cssClass]: cssClass
			}"
			:size="!size ? 'medium' : size"
			:color="color"
			:type="iconType"
			@mousedown.native="mouseDownHandler"
			@mouseup.native="mouseUpHandler"
		/>
		<Icon
			:cssClass="{
				'vc-icon-button': true,
				'vc-icon-button--mouse-actions': true,
				'vc-icon-button--mobile': true,
				'vc-icon-button--disabled': disabled,
				[cssClass]: cssClass
			}"
			:size="!size ? 'medium' : size"
			:color="color"
			:type="iconType"
			:touchStart="mouseDownHandler"
			:touchEnd="mouseUpHandler"
		/>
	</span>
</template>

<script>
import { getThemeColor } from "../../utils";
import Icon from "../Icon";
import "./IconButton.scss";

export default {
	name: "IconButton",
	data: () => ({
		color: getThemeColor()
	}),
	components: {
		Icon
	},
	props: {
		actionHandler: Function,
		mouseDownHandler: Function,
		mouseUpHandler: Function,
		iconType: String,
		cssClass: String,
		size: String,
		disabled: Boolean,
		touchStart: {
			type: Function,
			default: () => null
		},
		touchEnd: {
			type: Function,
			default: () => null
		}
	}
};
</script>
