var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"messageArea",class:{
		'vc-message-area': true,
		'vc-message-area--flex': !_vm.browserIE
	}},[(_vm.isVoiceRecordingActive)?_c('div',[_c('div',{staticClass:"vc-message-area__status"},[_vm._v("\n\t\t\t"+_vm._s(_vm.voiceRecordingMsg)+"\n\t\t")]),_c('div',{staticClass:"vc-message-area__flashing-dots"})]):[_c('div',{staticClass:"vc-message-area__fill-spacer"}),_vm._l((_vm.messages),function(message){return [(
					message.type === _vm.MSG_TEXT_TYPE &&
						message.state === _vm.MSG_RENDERED_STATE &&
						message.content &&
						message.content.type === 'carousel'
				)?_c('ChatCarousel',{key:message.id,attrs:{"items":message.content.items}}):(
					message.type === _vm.MSG_TEXT_TYPE &&
						message.state === _vm.MSG_RENDERED_STATE
				)?_c('MessageBubble',{key:message.id,attrs:{"messageContent":message.data,"time":message.time,"isRobot":message.isRobot,"isLastInBlock":message.isLastInBlock}}):(
					message.type === _vm.MSG_OPTION_TYPE &&
						message.state === _vm.MSG_RENDERED_STATE
				)?_c('OptionsContainer',{key:message.id,attrs:{"options":message.data,"isActive":message.isOptionsActive}}):(message.state === _vm.MSG_WAITING_STATE)?_c('MessageBubble',{key:message.id,attrs:{"isTyping":"","isRobot":""}}):_vm._e(),(
					message.type === _vm.MSG_GEOCODE_TYPE &&
						message.state === _vm.MSG_RENDERED_STATE
				)?_c('GoogleMap',{key:message.id,attrs:{"id":message.id,"isActive":message.isOptionsActive}}):_vm._e()]})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }