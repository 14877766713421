import { API_URL_BASE } from "../constants";

const getApiUrl = () =>
	window.vocallsChatbot
		? window.vocallsChatbot.apiEndpoint
			? window.vocallsChatbot.apiEndpoint
			: API_URL_BASE
		: API_URL_BASE;

export default getApiUrl;
