/**
 * Message state module
 */

import {
	MSG_OPTION_TYPE,
	MSG_GEOCODE_TYPE,
	MSG_GET_INTERVAL_TIME,
	MSG_RENDERED_STATE
} from "../constants";
import {
	getMessage,
	isNewLastTextMessageInBlock,
	getLastFromArray
} from "../utils";
import { mapTranslation } from "../localization";

const messagesModule = {
	state: {
		messages: [],
		messagesGetterInterval: null,
		messageActiveInput: true,
		messageInputPlaceholder: ""
	},
	getters: {
		messages: state => state.messages,
		messageActiveInput: state => state.messageActiveInput,
		messageInputPlaceholder: state => state.messageInputPlaceholder,
		getNextMessage: state => id => {
			const currentMsgIndex = state.messages.findIndex(msg => msg.id === id);
			return state.messages[currentMsgIndex + 1];
		}
	},
	mutations: {
		setMessages: (state, data) => (state.messages = data),
		addMessage: (state, messageSettings) => {
			const currentDate = new Date();
			const currentHour = currentDate.getHours();
			const currentMinutes = currentDate.getMinutes();
			const timestamp = `${currentHour} : ${
				currentMinutes > 9 ? currentMinutes : `0${currentMinutes}`
			}`;
			const isLastMessage = isNewLastTextMessageInBlock(
				messageSettings,
				getLastFromArray(state.messages)
			);

			state.messages = [
				...state.messages,
				{
					...messageSettings,
					isLastInBlock: isLastMessage,
					time: timestamp,
					data: messageSettings.data
				}
			];
		},
		setMessageState: (state, { id, messageState }) => {
			const selectedMessage = state.messages.find(msg => msg.id === id);
			selectedMessage.state = messageState;
		},
		setAllMessagesRenderedState: state =>
			state.messages.map(msg => (msg.state = MSG_RENDERED_STATE)),
		setSelectedOption: (state, optionId) => {
			getLastFromArray(state.messages).data.find(
				option => option.id === optionId
			).isSelected = true;
		},
		clearMessages: state => (state.messages = []),
		deactivateOptions: state => {
			const disabledOptions = state.messages.map(message => ({
				...message,
				...(message.type === MSG_OPTION_TYPE && { isOptionsActive: false }),
				...(message.type === MSG_GEOCODE_TYPE && { isOptionsActive: false })
			}));
			state.messages = disabledOptions;
		},
		toggleLastInBlock: state => {
			getLastFromArray(state.messages).isLastInBlock = false;
		},
		startMessageGetterInterval: state => {
			state.messagesGetterInterval = setInterval(
				() => getMessage(),
				MSG_GET_INTERVAL_TIME
			);
		},
		stopMessageGetterInterval: state => {
			state.messagesGetterInterval = clearInterval(
				state.messagesGetterInterval
			);
		},
		setMessageActiveInput: (state, isActive) => {
			state.messageActiveInput = isActive;
			isActive
				? (state.messageInputPlaceholder = mapTranslation("typeMessage"))
				: null;
		},
		setMessageInputPlaceholder: (state, value) => {
			state.messageInputPlaceholder = value;
		}
	}
};

export default messagesModule;
