import {
	MSG_TEXT_TYPE,
	MSG_WAIT_TYPE,
	MSG_OPTION_TYPE,
	MSG_CONVERSATION_END,
	MSG_REDIRECT_TYPE
} from "../constants";

export const isMessageTextType = message => message.type === MSG_TEXT_TYPE;
export const isMessageWaitType = message => message.type === MSG_WAIT_TYPE;
export const isMessageOptionType = message => message.type === MSG_OPTION_TYPE;
export const isMessageConversationEnd = message =>
	message.type === MSG_CONVERSATION_END || message.type === MSG_REDIRECT_TYPE;
