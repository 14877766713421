<template>
	<div class="vc-carousel">
		<carousel
			:per-page="this.isWideModeEnabled ? 2 : 1"
			:paginationEnabled="true"
			:navigationEnabled="true"
			navigationNextLabel=""
			navigationPrevLabel=""
			:key="this.isWideModeEnabled"
		>
			<slide v-for="item in items" :key="item.url">
				<div class="vc-carousel__item" @click="() => redirectToLink(item.url)">
					<div v-if="item.image" class="vc-carousel__img">
						<img :src="item.image" />
					</div>
					<div class="vc-carousel__title" v-if="item.title">
						{{ item.title }}
					</div>
					<div
						class="vc-carousel__desc"
						v-if="item.description"
						v-html="item.description"
					/>
				</div>
			</slide>
		</carousel>
	</div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import { mapGetters } from "vuex";

import "./ChatCarousel.scss";

export default {
	name: "ChatCarousel",

	props: {
		items: Array
	},

	methods: {
		redirectToLink(url) {
			window.open(url, "_blank").focus();
		}
	},

	computed: {
		...mapGetters(["isWideModeEnabled"])
	},

	components: {
		Carousel,
		Slide
	}
};
</script>
