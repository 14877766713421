import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import { CHATBOT_CONTAINER_ID } from "./constants";

import * as VueGoogleMaps from "vue2-google-maps";
import Vue2TouchEvents from "vue2-touch-events";

Vue.use(VueGoogleMaps, {
	load: {
		key: "AIzaSyBb3mWAC23Tqw5IBhZ81Rj6HK3th49TQp8",
		libraries: "places"
	},

	installComponents: true
});

Vue.use(Vue2TouchEvents);

const vueMainConfig = () =>
	new Vue({
		store,
		render: h => h(App)
	}).$mount(`#${CHATBOT_CONTAINER_ID}`);

export default vueMainConfig;
