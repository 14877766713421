var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vc-message-input__container"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:"input",class:{
			'vc-message-input': true,
			'vc-message-input--recording': _vm.isVoiceRecordingActive
		},attrs:{"type":"text","placeholder":_vm.isVoiceRecordingActive ? '' : _vm.messageInputPlaceholder,"disabled":!_vm.messageActiveInput || _vm.isVoiceRecordingActive},domProps:{"value":(_vm.inputValue)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return (() => _vm.sendMessage()).apply(null, arguments)},"click":() => _vm.blurInput(),"input":function($event){if($event.target.composing)return;_vm.inputValue=$event.target.value}}}),(
			(!_vm.isVoiceRecordingActive &&
				_vm.messageActiveInput &&
				_vm.inputValue.trim() === '' &&
				_vm.enableVoiceInput) ||
				(_vm.isRecordingBtnHolding && _vm.recordingInProgress) ||
				(_vm.isVoiceRecordingActive && _vm.isRecordingInitStage)
		)?_c('IconButton',{attrs:{"iconType":"micrpohone","cssClass":"vc-icon-button--in-input","size":"extra-large","mouseDownHandler":_vm.handleRecordingButtonDown,"mouseUpHandler":_vm.handleRecordingButtonUp,"touchStart":_vm.handleRecordingButtonDown,"touchEnd":_vm.handleRecordingButtonUp}}):_vm._e(),(!_vm.isVoiceRecordingActive)?_c('IconButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.inputValue.trim() !== ''),expression:"inputValue.trim() !== ''"}],attrs:{"iconType":"send-message","cssClass":"vc-icon-button--in-input","size":"extra-large","actionHandler":_vm.sendMessage}}):_vm._e(),_c('IconButton',{directives:[{name:"show",rawName:"v-show",value:(
			_vm.isVoiceRecordingActive && _vm.recordingInProgress && !_vm.isRecordingBtnHolding
		),expression:"\n\t\t\tisVoiceRecordingActive && recordingInProgress && !isRecordingBtnHolding\n\t\t"}],attrs:{"iconType":"stop-circle","cssClass":"vc-icon-button--in-input  vc-icon-button--pause vc-icon-button--secondary","size":"extra-large","actionHandler":() => _vm.stopRecording(true)}}),_c('IconButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.isVoiceRecordingActive && !_vm.isRecordingBtnHolding),expression:"isVoiceRecordingActive && !isRecordingBtnHolding"}],attrs:{"iconType":"cross","cssClass":"vc-icon-button--in-input vc-icon-button--secondary","size":"extra-large","actionHandler":_vm.cancelVoiceRecording}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }