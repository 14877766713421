var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isGeoConfirmation)?_c('div',{class:{
		'vc-options-container': true,
		'vc-options-container--active': _vm.isActive
	}},_vm._l((_vm.options),function(option){return _c('Button',{key:option.id,attrs:{"label":option.text,"actionHandler":() => _vm.sendMessage(option.id),"isSelected":option.isSelected,"activeColor":_vm.activeColor}})}),1):_c('div',{class:{
		'vc-options-container': true,
		'vc-options-container--active': _vm.isActive
	}},_vm._l((_vm.options),function(option){return _c('Button',{key:option.id,attrs:{"label":option.text,"actionHandler":() => _vm.sendGeoMessage(option.id),"isSelected":_vm.geoOptionIdSelected === option.id,"activeColor":_vm.activeColor}})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }