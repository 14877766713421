<template>
	<div
		v-if="!isGeoConfirmation"
		:class="{
			'vc-options-container': true,
			'vc-options-container--active': isActive
		}"
	>
		<Button
			v-for="option in options"
			:key="option.id"
			:label="option.text"
			:actionHandler="() => sendMessage(option.id)"
			:isSelected="option.isSelected"
			:activeColor="activeColor"
		/>
	</div>
	<div
		v-else
		:class="{
			'vc-options-container': true,
			'vc-options-container--active': isActive
		}"
	>
		<Button
			v-for="option in options"
			:key="option.id"
			:label="option.text"
			:actionHandler="() => sendGeoMessage(option.id)"
			:isSelected="geoOptionIdSelected === option.id"
			:activeColor="activeColor"
		/>
	</div>
</template>

<script>
import { mapMutations } from "vuex";
import { MSG_OPTION_TYPE, MSG_GEOCODE_TYPE } from "../../constants";
import { sendAndProcessMessage, getThemeColor } from "../../utils";
import Button from "../Button";
import "./OptionsContainer.scss";

export default {
	name: "OptionsContainer",
	data: () => ({
		selectedButtonId: null,
		activeColor: getThemeColor(),
		geoOptionIdSelected: null
	}),
	components: {
		Button
	},
	props: {
		options: Array,
		isActive: Boolean,
		isGeoConfirmation: Boolean,
		payload: {}
	},
	methods: {
		...mapMutations(["setSelectedOption"]),

		sendMessage(optionId) {
			this.setSelectedOption(optionId);
			sendAndProcessMessage(Number(optionId), MSG_OPTION_TYPE);
		},

		sendGeoMessage(optionId) {
			const { lat, lng, adress } = this.payload;
			this.isGeoActive = false;
			this.geoOptionIdSelected = optionId;

			optionId.includes("agree")
				? sendAndProcessMessage(
						{
							longitude: lng,
							latitude: lat,
							formattedAddress: adress
						},
						MSG_GEOCODE_TYPE
				  )
				: sendAndProcessMessage(
						{
							longitude: null,
							latitude: null,
							formattedAddress: null
						},
						MSG_GEOCODE_TYPE
				  );
		}
	}
};
</script>
