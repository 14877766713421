const getUrlParameters = () => {
	const result = [];
	const paramsString = window.location.search;

	paramsString
		.substr(1)
		.split("&")
		.map(queryParam => {
			const item = queryParam.split("=");
			result.push({ name: item[0], value: decodeURIComponent(item[1]) });
		});

	if (
		!result.find(({ name }) => name === "language") &&
		window.vocallsChatbot
	) {
		window.vocallsChatbot.language
			? result.push({ name: "language", value: window.vocallsChatbot.language })
			: null;
	}

	return result.filter(
		({ name, value }) => name !== "" && value !== "undefined"
	);
};

export default getUrlParameters;
