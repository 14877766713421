import { getApiKey } from "../utils";
import { COOKIE_BASE_NAME } from "../constants";

/**
 * Toggle states module
 */

const toggleStatesModule = {
	state: {
		isChatbotOpen: false,
		isDropdownMenuOpen: false,
		isWideModeEnabled: false,
		isVoiceRecordingActive: false,
		voiceRecordingMsg: "",
		language: "cs",
		apiToken: "",
		isConversationEnded: false,
		isEndChatDialogActive: false,
		isChatbotHidden: false
	},
	getters: {
		isChatbotOpen: state => state.isChatbotOpen,
		isDropdownMenuOpen: state => state.isDropdownMenuOpen,
		isWideModeEnabled: state => state.isWideModeEnabled,
		isVoiceRecordingActive: state => state.isVoiceRecordingActive,
		voiceRecordingMsg: state => state.voiceRecordingMsg,
		language: state => state.language,
		apiToken: state => state.apiToken,
		isConversationEnded: state => state.isConversationEnded,
		isEndChatDialogActive: state => state.isEndChatDialogActive,
		isChatbotHidden: state => state.isChatbotHidden
	},
	mutations: {
		toggleChatbot: state => {
			state.isChatbotOpen = !state.isChatbotOpen;
			state.isDropdownMenuOpen = false;
		},

		toggleEndChatDialog: state =>
			(state.isEndChatDialogActive = !state.isEndChatDialogActive),

		toggleDropdownMenu: state =>
			(state.isDropdownMenuOpen = !state.isDropdownMenuOpen),

		toggleWideMode: state =>
			(state.isWideModeEnabled = !state.isWideModeEnabled),

		toggleVoiceRecording: state =>
			(state.isVoiceRecordingActive = !state.isVoiceRecordingActive),

		setConversationEnd: (state, payload) =>
			(state.isConversationEnded = payload),

		setVoiceRecordingMsg: (state, msg) => (state.voiceRecordingMsg = msg),

		setLanguage: (state, lang) => (state.language = lang),

		setApiToken: (state, token) => {
			localStorage.setItem(`${COOKIE_BASE_NAME}_${getApiKey()}`, token);
			state.apiToken = token;
		},

		toggleChatbotHidden: state => {
			state.isChatbotHidden = !state.isChatbotHidden;
		}
	}
};

export default toggleStatesModule;
