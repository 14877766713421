<template>
	<ChatbotRoot />
</template>

<script>
import ChatbotRoot from "./components/ChatbotRoot";
import "./assets/scss/front.scss";

export default {
	name: "App",
	components: {
		ChatbotRoot
	}
};
</script>
