import { MSG_OPTION_TYPE, MSG_TEXT_TYPE, MSG_WAIT_TYPE } from "../constants";
import { removeTagsFromString } from "../utils";

const MSG_TIME_MULTIPLIER = 30;
const MSG_BASE_WAITING_TIME = 500;
const MSG_MAX_TIME = 3000;

const getWaitingTime = (data, index) => {
	let waitingTime = 0;

	data.slice(0, index).map(message => {
		if (message.type === MSG_TEXT_TYPE) {
			const pureText = removeTagsFromString(message.value);
			const textLengthAddition = pureText.length * MSG_TIME_MULTIPLIER;
			const additionalWaitingTime = MSG_BASE_WAITING_TIME + textLengthAddition;

			if (message.delay) {
				waitingTime = waitingTime + message.delay;
			} else {
				waitingTime =
					waitingTime +
					(additionalWaitingTime > MSG_MAX_TIME
						? MSG_MAX_TIME
						: additionalWaitingTime);
			}
		}

		if (message.type === MSG_OPTION_TYPE && message.delay) {
			waitingTime = waitingTime + message.delay;
		}

		if (message.type === MSG_WAIT_TYPE) {
			waitingTime = waitingTime + message.value;
		}
	});

	return waitingTime;
};

export default getWaitingTime;
