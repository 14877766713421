<template>
	<div
		class="vc-toggle-controller"
		:style="{ borderColor: borderColor }"
		@click="actionHandler"
		@mouseover="mouseOver"
		@mouseleave="mouseLeave"
	>
		<div
			class="vc-toggle-controller__inner"
			:style="{
				backgroundImage: `linear-gradient(211deg, ${colorPrimary}, ${colorSecondary})`,
				borderColor: colorPrimary
			}"
		>
			<div class="vc-toggle-controller__icon">
				<MessageBubbleIcon :width="40" :height="40" color="#ffffff" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { getThemeColor, getColorWithOpacity } from "../../utils";
import MessageBubbleIcon from "../SvgIcons/MessageBubbleIcon";
import "./ToggleController.scss";

export default {
	name: "ToggleController",
	data: () => ({
		colorPrimary: getColorWithOpacity(getThemeColor(), 0.6),
		colorSecondary: getThemeColor(),
		borderColor: getColorWithOpacity(getThemeColor(), 0.6)
	}),
	props: {
		actionHandler: Function
	},
	components: {
		MessageBubbleIcon
	},
	methods: {
		mouseOver() {
			this.colorPrimary = this.colorSecondary;
		},
		mouseLeave() {
			this.colorPrimary = getColorWithOpacity(getThemeColor(), 0.6);
		}
	},
	computed: {
		...mapGetters(["isChatbotOpen"])
	}
};
</script>
