var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
		'vc-button': true,
		'vc-button--selected': _vm.isSelected
	},style:({
		backgroundColor: _vm.isSelected ? _vm.activeColor : '#fff',
		borderColor: _vm.activeColor
	}),domProps:{"innerHTML":_vm._s(_vm.label)},on:{"click":_vm.actionHandler}})
}
var staticRenderFns = []

export { render, staticRenderFns }