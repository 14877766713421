<template>
	<div class="vc-message-input__container">
		<input
			ref="input"
			type="text"
			:class="{
				'vc-message-input': true,
				'vc-message-input--recording': isVoiceRecordingActive
			}"
			:placeholder="isVoiceRecordingActive ? '' : messageInputPlaceholder"
			:disabled="!messageActiveInput || isVoiceRecordingActive"
			v-model="inputValue"
			@keyup.enter="() => sendMessage()"
			@click="() => blurInput()"
		/>
		<IconButton
			v-if="
				(!isVoiceRecordingActive &&
					messageActiveInput &&
					inputValue.trim() === '' &&
					enableVoiceInput) ||
					(isRecordingBtnHolding && recordingInProgress) ||
					(isVoiceRecordingActive && isRecordingInitStage)
			"
			iconType="micrpohone"
			cssClass="vc-icon-button--in-input"
			size="extra-large"
			:mouseDownHandler="handleRecordingButtonDown"
			:mouseUpHandler="handleRecordingButtonUp"
			:touchStart="handleRecordingButtonDown"
			:touchEnd="handleRecordingButtonUp"
		/>
		<IconButton
			v-if="!isVoiceRecordingActive"
			iconType="send-message"
			cssClass="vc-icon-button--in-input"
			size="extra-large"
			:actionHandler="sendMessage"
			v-show="inputValue.trim() !== ''"
		/>
		<IconButton
			v-show="
				isVoiceRecordingActive && recordingInProgress && !isRecordingBtnHolding
			"
			iconType="stop-circle"
			cssClass="vc-icon-button--in-input  vc-icon-button--pause vc-icon-button--secondary"
			size="extra-large"
			:actionHandler="() => stopRecording(true)"
		/>
		<IconButton
			v-show="isVoiceRecordingActive && !isRecordingBtnHolding"
			iconType="cross"
			cssClass="vc-icon-button--in-input vc-icon-button--secondary"
			size="extra-large"
			:actionHandler="cancelVoiceRecording"
		/>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import { MSG_TEXT_TYPE } from "../../constants";
import languageParameters from "../../constants/languageParameters";
import { mapTranslation } from "../../localization";
import { sendAndProcessMessage } from "../../utils";
import IconButton from "../IconButton";
import "./MessageInput.scss";

export default {
	name: "MessageInput",
	components: { IconButton },
	data: () => ({
		inputValue: "",
		recorder: {},
		input: {},
		processor: {},
		socket: {},
		recordingMessage: "",
		enableVoiceInput: true,
		recordingInProgress: false,
		isRecordingCanceled: false,
		isRecordingBtnHolding: false,
		isRecordingInitStage: false,
		mouseBtnHoldTimeout: null
	}),
	computed: {
		...mapGetters([
			"messageActiveInput",
			"messageInputPlaceholder",
			"messageInputGeo",
			"isVoiceRecordingActive",
			"language"
		])
	},
	methods: {
		...mapMutations([
			"toggleVoiceRecording",
			"setVoiceRecordingMsg",
			"startMessageGetterInterval",
			"stopMessageGetterInterval"
		]),

		handleRecordingButtonDown() {
			this.isRecordingBtnHolding = false;
			this.isRecordingInitStage = true;
			this.recordVoice();

			this.mouseBtnHoldTimeout = setTimeout(() => {
				this.isRecordingBtnHolding = true;
				this.isRecordingInitStage = false;
			}, 500);
		},

		handleRecordingButtonUp() {
			this.isRecordingInitStage = false;
			clearTimeout(this.mouseBtnHoldTimeout);
			this.isRecordingBtnHolding ? this.stopRecording(true) : null;
		},

		sendMessage() {
			sendAndProcessMessage(this.inputValue, MSG_TEXT_TYPE);
			this.inputValue = "";
		},

		async recordVoice() {
			const queryString = window.location.search;
			const urlParams = new URLSearchParams(queryString);
			const chatbotConfig = window.vocallsChatbot || {};

			const languageCode =
				urlParams.get("recordTtsCode") ||
				chatbotConfig.recordTtsCode ||
				languageParameters[this.language] ||
				languageParameters.en;

			const engine =
				urlParams.get("recordTtsEngine") ||
				chatbotConfig.recordTtsEngine ||
				"Google";

			this.socket = new WebSocket(
				`${process.env.VUE_APP_WSS_CORE_URL}/Administration/Tools/SttGenerator?language=${languageCode}&engine=${engine}`
			);

			this.socket.addEventListener("message", ({ data }) => {
				const { type, message } = JSON.parse(data);

				switch (type) {
					case 0: {
						this.stopRecording();
						break;
					}
					case 1: {
						this.setVoiceRecordingMsg(message);
						this.socket.close();

						setTimeout(() => {
							if (!this.isRecordingCanceled) {
								this.toggleVoiceRecording();
								sendAndProcessMessage(message, MSG_TEXT_TYPE);
							}
						}, 2000);
					}
				}
			});

			this.socket.addEventListener("open", async () => {
				this.recordingInProgress = true;
				this.isRecordingCanceled = false;

				this.setVoiceRecordingMsg(mapTranslation("voiceRecordStart"));
				this.toggleVoiceRecording();
				this.stopMessageGetterInterval();

				const stream = await navigator.mediaDevices.getUserMedia({
					audio: { sampleRate: 16000, channelCount: 1 }
				});

				this.recorder = new MediaRecorder(stream);
				const bufferSize = 1024 * 16;
				const audioContext = new AudioContext({ sampleRate: 16000 });
				this.processor = audioContext.createScriptProcessor(bufferSize, 1, 1);

				this.processor.connect(audioContext.destination);
				this.input = audioContext.createMediaStreamSource(stream);
				this.input.connect(this.processor);

				this.processor.onaudioprocess = e => {
					const buffer = e.inputBuffer.getChannelData(0);

					this.socket.send(JSON.stringify({ type: 2, buffer: [...buffer] }));
				};

				this.recorder.start();
			});
		},

		cancelVoiceRecording() {
			this.isRecordingCanceled = true;

			if (this.recordingInProgress) {
				this.recorder.stop();
				this.input.disconnect();
				this.processor.disconnect();
			}

			this.socket.close();
			this.startMessageGetterInterval();
			this.toggleVoiceRecording();
		},

		stopRecording(withSocketMsg = false) {
			this.recorder.stop();
			this.input.disconnect();
			this.processor.disconnect();
			this.setVoiceRecordingMsg(mapTranslation("voiceRecordProcessing"));
			this.recordingInProgress = false;

			if (withSocketMsg) {
				this.socket.send(
					JSON.stringify({ type: 1, message: null, buffer: null })
				);
			}
		},

		blurInput() {
			this.$refs.input.focus();
		}
	},

	created() {
		window.vocallsChatbot
			? (this.enableVoiceInput = window.vocallsChatbot.enableVoiceInput)
			: null;
	}
};
</script>
