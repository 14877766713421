var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['google-map', !_vm.isActive ? 'google-map--disabled' : '']},[_c('GmapMap',{ref:"map",staticStyle:{"width":"100%","height":"200px"},attrs:{"center":{ lat: 50.08464999797192, lng: 14.446458232232185 },"zoom":7,"options":{
			mapTypeControl: false,
			scaleControl: false,
			streetViewControl: false
		}},on:{"click":({ latLng }) => _vm.setCurrentPos(latLng)}},[(_vm.isLocationInitialized)?_c('GmapMarker',{attrs:{"position":{ lat: _vm.currentPos.lat, lng: _vm.currentPos.lng },"clickable":true,"draggable":true},on:{"dragend":({ latLng }) => _vm.setCurrentPos(latLng)}}):_vm._e()],1),_c('OptionsContainer',{attrs:{"options":[
			{ id: `${_vm.id}-agree`, text: 'Potvrdit' },
			{ id: `${_vm.id}-decline`, text: 'Nechci zadávat adresu' }
		],"isActive":_vm.isActive,"isGeoConfirmation":true,"payload":_vm.currentPos}}),(_vm.isActive)?_c('gmap-autocomplete',{attrs:{"placeholder":"Zadejte lokaci nebo vyberte na mapě","select-first-on-enter":true,"value":_vm.inputValue},on:{"place_changed":place => _vm.setCurrentPos(place.geometry.location),"click":e => _vm.locateDropdown(e.target),"input":e => _vm.locateDropdown(e.target)}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }