export const API_URL_BASE = process.env.VUE_APP_API_URL;
export const API_KEY_BASE = process.env.VUE_APP_API_KEY;

export const MSG_TEXT_TYPE = "MESSAGE";
export const MSG_WAIT_TYPE = "WAIT";
export const MSG_OPTION_TYPE = "CHOICE";
export const MSG_CONVERSATION_END = "END";
export const MSG_GEOCODE_TYPE = "GEOCODE";
export const MSG_REDIRECT_TYPE = "REDIRECT";

export const MSG_GET_INTERVAL_TIME = 5000;

export const COOKIE_BASE_NAME = "VocallsSessionId";
export const STORAGE_BASE_NAME = "vcChatbotData";
