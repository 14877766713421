const getBrowserParameters = () => [
	{ name: "url", value: window ? window.location.toString() : "" },
	{ name: "userAgent", value: navigator ? navigator.userAgent : "" },
	{
		name: "screenSize",
		value:
			window && window.screen
				? `${window.screen.height}x${window.screen.width}`
				: ""
	},
	{ name: "title", value: document ? document.title : "" }
];

export default getBrowserParameters;
