var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vc-toggle-controller",style:({
		borderColor: _vm.borderColor,
		...(_vm.zIndexCustom && { zIndex: _vm.zIndexCustom })
	}),on:{"click":_vm.actionHandler,"mouseover":_vm.mouseOver,"mouseleave":_vm.mouseLeave}},[_c('div',{staticClass:"vc-toggle-controller__inner",style:({
			backgroundImage: `linear-gradient(211deg, ${_vm.colorPrimary}, ${_vm.colorSecondary})`,
			borderColor: _vm.colorPrimary
		})},[_c('div',{staticClass:"vc-toggle-controller__icon"},[_c('MessageBubbleIcon',{attrs:{"width":40,"height":40,"color":"#ffffff"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }