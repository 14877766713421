<template>
	<div
		:class="{
			'vc-chat-window': true,
			'vc-chat-window--wide-mode': isWideModeEnabled
		}"
	>
		<Toolbar />
		<DropdownMenu v-if="isDropdownMenuOpen" />
		<MessageArea />
		<MessageInput />
	</div>
</template>

<script>
import { mapGetters } from "vuex";

import MessageArea from "../MessageArea";
import MessageInput from "../MessageInput";
import Toolbar from "../Toolbar";
import DropdownMenu from "../DropdownMenu";

import "./ChatWindow.scss";

export default {
	name: "ChatWindow",
	components: {
		MessageArea,
		MessageInput,
		Toolbar,
		DropdownMenu
	},
	computed: {
		...mapGetters(["isDropdownMenuOpen", "isWideModeEnabled"])
	}
};
</script>
