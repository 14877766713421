<template>
	<span
		:class="className"
		:style="{ color: color }"
		v-touch:start="touchStart"
		v-touch:end="touchEnd"
	/>
</template>

<script>
export default {
	name: "Icon",
	data: ({ type, size, cssClass }) => ({
		className: {
			icon: true,
			[`icon--${type}`]: true,
			[`text--${size}`]: size,
			...cssClass
		}
	}),
	props: {
		type: String,
		size: String,
		color: String,
		cssClass: Object,
		touchStart: {
			type: Function,
			default: () => null
		},
		touchEnd: {
			type: Function,
			default: () => null
		}
	}
};
</script>
