var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isTyping)?_c('div',{staticClass:"vc-message-bubble vc-message-bubble--is-typing"},[_vm._m(0)]):_c('div',{class:{
		'vc-message-bubble': true,
		'vc-message-bubble--reverted': !_vm.isRobot,
		'vc-message-bubble--is-last': _vm.isLastInBlock
	},style:({ backgroundColor: !_vm.isRobot ? _vm.userBubbleColor : '#fff' }),domProps:{"innerHTML":_vm._s(
		`${_vm.messageContent} <span class='vc-message-bubble__postfix'>${_vm.time}</span>`
	)}})
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vc-message-bubble__dots-container"},[_c('div',{staticClass:"vc-message-bubble__dot"}),_c('div',{staticClass:"vc-message-bubble__dot"}),_c('div',{staticClass:"vc-message-bubble__dot"})])
}]

export { render, staticRenderFns }