import store from "../store";
import { getMessage as getMessageFromApi } from "../api";
import mapMessagesWithTyping from "./mapMessagesWithTyping";

const getMessage = () => {
	getMessageFromApi().then(({ data }) => {
		if (data.length > 0) {
			store.commit("deactivateOptions");
			store.commit("stopMessageGetterInterval");

			mapMessagesWithTyping(data);
		}
	});
};

export default getMessage;
