<template>
	<div
		:class="{
			'vc-message-area': true,
			'vc-message-area--flex': !browserIE
		}"
		ref="messageArea"
	>
		<div v-if="isVoiceRecordingActive">
			<div class="vc-message-area__status">
				{{ voiceRecordingMsg }}
			</div>
			<div class="vc-message-area__flashing-dots"></div>
		</div>
		<template v-else>
			<div class="vc-message-area__fill-spacer" />
			<template v-for="message in messages">
				<ChatCarousel
					v-if="
						message.type === MSG_TEXT_TYPE &&
							message.state === MSG_RENDERED_STATE &&
							message.content &&
							message.content.type === 'carousel'
					"
					:key="message.id"
					:items="message.content.items"
				/>
				<MessageBubble
					v-else-if="
						message.type === MSG_TEXT_TYPE &&
							message.state === MSG_RENDERED_STATE
					"
					:key="message.id"
					:messageContent="message.data"
					:time="message.time"
					:isRobot="message.isRobot"
					:isLastInBlock="message.isLastInBlock"
				/>
				<OptionsContainer
					v-else-if="
						message.type === MSG_OPTION_TYPE &&
							message.state === MSG_RENDERED_STATE
					"
					:key="message.id"
					:options="message.data"
					:isActive="message.isOptionsActive"
				/>
				<MessageBubble
					v-else-if="message.state === MSG_WAITING_STATE"
					:key="message.id"
					isTyping
					isRobot
				/>
				<GoogleMap
					v-if="
						message.type === MSG_GEOCODE_TYPE &&
							message.state === MSG_RENDERED_STATE
					"
					:key="message.id"
					:id="message.id"
					:isActive="message.isOptionsActive"
				/>
			</template>
		</template>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

import {
	MSG_QUEUED_STATE,
	MSG_WAITING_STATE,
	MSG_RENDERED_STATE,
	MSG_TEXT_TYPE,
	MSG_OPTION_TYPE,
	MSG_GEOCODE_TYPE
} from "../../constants";
import { isBrowserIE } from "../../utils";
import MessageBubble from "../MessageBubble";
import OptionsContainer from "../OptionsContainer";
import GoogleMap from "../GoogleMap";
import ChatCarousel from "../ChatCarousel";
import "./MessageArea.scss";

export default {
	name: "MessageArea",
	components: {
		MessageBubble,
		OptionsContainer,
		GoogleMap,
		ChatCarousel
	},
	data: () => ({
		browserIE: isBrowserIE(),
		MSG_QUEUED_STATE,
		MSG_WAITING_STATE,
		MSG_RENDERED_STATE,
		MSG_TEXT_TYPE,
		MSG_OPTION_TYPE,
		MSG_GEOCODE_TYPE
	}),
	computed: {
		...mapGetters(["messages", "isVoiceRecordingActive", "voiceRecordingMsg"])
	},
	methods: {
		scrollDown() {
			const messageAreaRef = this.$refs.messageArea;
			messageAreaRef.scrollTop = messageAreaRef.scrollHeight;
		}
	},
	watch: {
		messages: {
			deep: true,
			handler() {
				setTimeout(() => this.scrollDown(), 200);
			}
		}
	}
};
</script>
