import store from "../store";
import messages from "./messages";

const mapTranslation = msgKey => {
	const lang = store.getters.language;

	return messages[lang] ? messages[lang][msgKey] : messages.en[msgKey];
};

export default mapTranslation;
