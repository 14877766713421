var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.actionHandler)?_c('Icon',{attrs:{"cssClass":{
		'vc-icon-button': true,
		[_vm.cssClass]: _vm.cssClass,
		'vc-icon-button--disabled': _vm.disabled
	},"size":!_vm.size ? 'medium' : _vm.size,"color":_vm.color,"type":_vm.iconType},nativeOn:{"click":function($event){return _vm.actionHandler.apply(null, arguments)}}}):_c('span',[_c('Icon',{attrs:{"cssClass":{
			'vc-icon-button': true,
			'vc-icon-button--mouse-actions': true,
			'vc-icon-button--disabled': _vm.disabled,
			[_vm.cssClass]: _vm.cssClass
		},"size":!_vm.size ? 'medium' : _vm.size,"color":_vm.color,"type":_vm.iconType},nativeOn:{"mousedown":function($event){return _vm.mouseDownHandler.apply(null, arguments)},"mouseup":function($event){return _vm.mouseUpHandler.apply(null, arguments)}}}),_c('Icon',{attrs:{"cssClass":{
			'vc-icon-button': true,
			'vc-icon-button--mouse-actions': true,
			'vc-icon-button--mobile': true,
			'vc-icon-button--disabled': _vm.disabled,
			[_vm.cssClass]: _vm.cssClass
		},"size":!_vm.size ? 'medium' : _vm.size,"color":_vm.color,"type":_vm.iconType,"touchStart":_vm.mouseDownHandler,"touchEnd":_vm.mouseUpHandler}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }