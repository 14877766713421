<template>
	<div v-if="isTyping" class="vc-message-bubble vc-message-bubble--is-typing">
		<div class="vc-message-bubble__dots-container">
			<div class="vc-message-bubble__dot"></div>
			<div class="vc-message-bubble__dot"></div>
			<div class="vc-message-bubble__dot"></div>
		</div>
	</div>
	<div
		v-else
		v-html="
			`${messageContent} <span class='vc-message-bubble__postfix'>${time}</span>`
		"
		:style="{ backgroundColor: !isRobot ? userBubbleColor : '#fff' }"
		:class="{
			'vc-message-bubble': true,
			'vc-message-bubble--reverted': !isRobot,
			'vc-message-bubble--is-last': isLastInBlock
		}"
	/>
</template>

<script>
import { getThemeColor } from "../../utils";
import "./MessageBubble.scss";

export default {
	name: "MessageBubble",
	data: () => ({ userBubbleColor: getThemeColor() }),
	props: {
		isRobot: Boolean,
		isTyping: Boolean,
		isLastInBlock: Boolean,
		messageContent: String,
		time: String
	}
};
</script>
