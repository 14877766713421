export const CHATBOT_CONTAINER_ID = "vc-chatbot";
export const DEFAULT_THEME_COLOR = "#b526d8";
export const DEFAULT_TITLE = "Vocalls chatbot";

export const WINDOW_STATE_OPEN = "open";
export const WINDOW_STATE_MAXIMIZED = "maximized";
export const WINDOW_STATE_OPEN_AND_MAXIMIZED = "maximizedAndOpen";

export const MSG_QUEUED_STATE = "queued";
export const MSG_WAITING_STATE = "waiting";
export const MSG_RENDERED_STATE = "rendered";
