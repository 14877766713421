<template>
	<div :class="['google-map', !isActive ? 'google-map--disabled' : '']">
		<GmapMap
			ref="map"
			:center="{ lat: 50.08464999797192, lng: 14.446458232232185 }"
			:zoom="7"
			:options="{
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false
			}"
			style="width: 100%; height: 200px"
			@click="({ latLng }) => setCurrentPos(latLng)"
		>
			<GmapMarker
				v-if="isLocationInitialized"
				@dragend="({ latLng }) => setCurrentPos(latLng)"
				:position="{ lat: currentPos.lat, lng: currentPos.lng }"
				:clickable="true"
				:draggable="true"
			/>
		</GmapMap>
		<OptionsContainer
			:options="[
				{ id: `${id}-agree`, text: 'Potvrdit' },
				{ id: `${id}-decline`, text: 'Nechci zadávat adresu' }
			]"
			:isActive="isActive"
			:isGeoConfirmation="true"
			:payload="currentPos"
		/>
		<gmap-autocomplete
			v-if="isActive"
			placeholder="Zadejte lokaci nebo vyberte na mapě"
			@place_changed="place => setCurrentPos(place.geometry.location)"
			:select-first-on-enter="true"
			:value="inputValue"
			@click="e => locateDropdown(e.target)"
			@input="e => locateDropdown(e.target)"
		/>
	</div>
</template>

<script>
import { getAdressFromCoords } from "../../api";
import OptionsContainer from "../OptionsContainer";
import "./GoogleMap.scss";

export default {
	data: () => ({
		currentPos: { lat: 0, lng: 0 },
		locationConfirm: false,
		inputValue: "",
		isLocationInitialized: false
	}),
	name: "GoogleMap",
	components: { OptionsContainer },
	props: {
		isActive: Boolean,
		id: Number
	},
	methods: {
		getFormattedAdress() {
			getAdressFromCoords(this.currentPos).then(({ data }) => {
				const formattedAdress = data.results[0].formatted_address;

				this.currentPos.adress = formattedAdress;
				this.inputValue = formattedAdress;
			});
		},

		setCurrentPos(coords) {
			this.isLocationInitialized = true;
			this.currentPos = { lat: coords.lat(), lng: coords.lng() };
			this.getFormattedAdress();
			this.$refs.map.$mapPromise.then(map => {
				map.setCenter(this.currentPos);
				map.setZoom(12);
			});
		},

		getCurrentLocation() {
			this.$refs.map.$mapPromise.then(map => {
				navigator.geolocation.getCurrentPosition(({ coords }) => {
					const { latitude, longitude } = coords;
					const pos = { lat: latitude, lng: longitude };

					map.setCenter(pos);
					map.setZoom(12);

					this.currentPos = pos;
					this.getFormattedAdress();
					this.isLocationInitialized = true;
				});
			});
		},

		locateDropdown(el) {
			const { left, top, width } = el.getBoundingClientRect();
			const dropdown = document.querySelector(".pac-container");

			dropdown.style.bottom = `${window.innerHeight - top}px`;
			dropdown.style.left = `${left}px`;
			dropdown.style.width = `${width}px`;
		}
	},
	mounted() {
		this.getCurrentLocation();
	}
};
</script>
